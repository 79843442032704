import React, { useState } from "react";
import { Box, Checkbox, Typography, Grid, TextField } from "@mui/material";

export default function CheckboxGroup({ label, items, type, groupTitle ,changeItems }) {
  const [newItem, setNewItem] = useState("");
  const [showAddField, setShowAddField] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editedItemValue, setEditedItemValue] = useState("");
  const [errorMsg,setErrorMsg] = useState("");
  const [urlError, setUrlError] = useState(false);
  const [inTagerrorMsg,setInTagErrorMsg] = useState("");
  const [inTagurlError, setInTagUrlError] = useState(false);
  

  const handleInputChange = (e) => {
    if(e.target.value == ""){
      setErrorMsg("");
        setUrlError(false);
    }
    setNewItem(e.target.value);
  };

  const isDuplicate = (name) => {

    var filtring = items.filter(x=>x.displayName?.toLowerCase() == name?.toLowerCase())?.length;
    if(filtring > 0){
      return false;
    }else{
      return true;
    }

    // return items.some(n => {
    //   const seen = new Set();
    //   if (seen.has(n)) return n === name;
    //   seen.add(n);
    //   return false;
    // });
  };

  const handleAddItem = () => {
    if (newItem.trim() !== "") {
      const checkDup = isDuplicate(newItem)
      if(checkDup){
        setErrorMsg("");
        setUrlError(false);
        items.push({
          id: null,
          code: "",
          displayName: newItem,
          groupTitle: groupTitle,
          status: "ACTIVE",
          tagType: type,
        });
        setNewItem("");
        changeItems(items);
      }else{
        setUrlError(true);
        setErrorMsg(`The tag "${newItem}" is already there. Please change your tag name.`);
      }
    }
  };

  const handleDeleteItem = (index) => {
    items.splice(index, 1);
    setEditIndex(null);
    changeItems(items);
  };

  const handleAddCheckboxChange = (event) => {
    setShowAddField(event.target.checked);
  };

  const handleEditItem = (index,name) => {
    setInTagUrlError(false);
    setInTagErrorMsg("");
    setEditIndex(index);
    // if(items[index].displayName == "" && items[index].status == "INACTIVE"){
    //   index++;
    // }else{
    //   const value = items.findIndex(x=>x.displayName == name);
    //   index = value;
    // }
    // if(items.filter(x=>x.status == "INACTIVE").length > 0){
    //   const value = items.filter(x=>x.status == "INACTIVE").length;
    //   // Calculate and log index + inactiveCount
    // const result = index + value;
    //   index = result;
    // }
    setEditedItemValue(items[index].displayName);
    changeItems(items);
  };

  const handleEditedItemChange = (e) => {
    if(e.target.value == ""){
      setInTagUrlError(false);
      setInTagErrorMsg("");
    }
    setEditedItemValue(e.target.value);
  };

  const handleConfirmEdit = (index,name) => {
    const editedValue = editedItemValue.trim();
    const checkDup = isDuplicate(editedValue);
    if(!checkDup){
      if(name != editedValue && editedValue != ""){
      setInTagUrlError(true);
      setInTagErrorMsg(`The tag "${editedValue}" is already there. Please change your tag name.`);
      return;
      }
    }
    // if(items[index].displayName == "")//&& items[index].status == "INACTIVE")
    // {
    //   index++;
    // }else{
    //   const value = items.findIndex(x=>x.displayName == name);
    //   index = value;
    // }
    // items[index].displayName = editedValue;
    if (editedValue === "") {
     // var itemsData = items.filter((_, i) => i !== index);
     //setItemList(itemsData);
     //items[index].status = "INACTIVE";
     //items.splice(index, 1);
    }
    else {
      items[index].displayName = editedValue;
    }
    changeItems(items);
    setEditIndex(null);
    if (editedValue === "") {
      handleDeleteItem(index);
    } else {
      setEditIndex(null);
    }
  };

  const handleAddItemKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddItem();
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            {...label}
            sx={{ width: "16px", height: "16px", marginTop: "4px" }}
            onChange={handleAddCheckboxChange}
          />
          <Typography
            sx={{ marginLeft: "10px", fontSize: "16px", fontWeight: "400" }}
          >
            Seleziona per Aggiungere
          </Typography>
        </Box>
      </Grid>
      {showAddField && (
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="New Item"
              value={newItem}
              onChange={handleInputChange}
              onKeyPress={handleAddItemKeyPress}
              sx={{ marginRight: "10px" }}
              error={urlError}
              helperText={errorMsg}
            />
          </Box>
        </Grid>
      )}
      {items
        .filter((item) => item.displayName != null && item.displayName != "")
        .map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Box sx={{ display: "flex" }}>
              {editIndex === index ? (
                <>
                  <TextField
                    value={editedItemValue}
                    onChange={handleEditedItemChange}
                    error={inTagurlError}
                    helperText={inTagerrorMsg}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleConfirmEdit(index,item.displayName);
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <Checkbox
                    {...label}
                    sx={{ width: "16px", height: "16px", marginTop: "4px" }}
                  />
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                    onDoubleClick={() => handleEditItem(index,item.displayName)}
                  >
                    {item.displayName}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        ))}
    </Grid>
  );
}

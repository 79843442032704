import React, { useState ,useEffect,useMemo} from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function AddLocationModal({ isOpen, onClose,submitClick,initialData  }) {
  const [avesLocations, setAvesLocations] = useState(initialData?.avesLocations || []);
  const [nameLocalita, setNameLocalita] = useState(initialData?.name || '');
  const [regione, setRegione] = useState(initialData?.region || '');

  useEffect(() => {
    if (initialData) {
      // If editing, set the modal's state with the initialData
      setAvesLocations(initialData.avesLocations || []);
      setNameLocalita(initialData.name || '');
      setRegione(initialData.region || '');
    } else {
      // If adding a new location, clear the modal's state
      setAvesLocations([]);
      setNameLocalita('');
      setRegione('');
    }
  }, [initialData]);

  // To handle changes in "Name Località" and "Regione"
  const handleNameChange = (e) => {
    setNameLocalita(e.target.value);
  };

  const handleRegioneChange = (e) => {
    setRegione(e.target.value);
  };

  const handleFieldChange = (id, field, value) => {
    setAvesLocations(avesLocations.map(f => 
      f.id == id ? { ...f, [field]: value } : f
    ));
  };

  const handleAddFields = () => {
    const newField = {
      id:Date.now() + Math.random().toString(36).substring(2, 9),
      name: '',
      iatacode: '',
      dstcode: ''
    };
    setAvesLocations([...avesLocations, newField]);
  };

  const handleDeleteField = (id) => {
    setAvesLocations(avesLocations.filter(field => field.id !== id));
  };

  // Handle the submit action and pass the data up to the parent component
  const handleSubmit = () => {
    const data = {
        name: nameLocalita,
        region: regione,
        avesLocations
    };
    submitClick(data); // Call the submitClick prop with all the data
    onClose(); // Close the modal
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
          minWidth: 300,
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 2,
          }}
        >
          <Typography variant="h6">Aggiungi Località</Typography>
          <IconButton onClick={onClose}>
            <Typography>X</Typography>
          </IconButton>
        </Box>

         <TextField
          label="Name localita"
          variant="outlined"
          fullWidth
          margin="normal"
          value={nameLocalita}
          onChange={handleNameChange} 
        />
        <TextField
          label="Regione(opzionale)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={regione}
          onChange={handleRegioneChange} 
        /> 
        
        {avesLocations.map((field, index) => (
          <Box key={`${index + 1}`}>
            <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>{`${index + 1}`}</Typography>
            <TextField
              label="Nomesuaves"
              variant="outlined"
              fullWidth
              value={field.name}
              onChange={(e) => handleFieldChange(field.id, 'name', e.target.value)} // Update dynamic field
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => handleDeleteField(field.id)}>
                    <DeleteIcon />
                  </IconButton>
                ),
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
                mt: 2,
              }}
            >
              <TextField
                label="IATA"
                variant="outlined"
                fullWidth
                value={field.iatacode}
                onChange={(e) => handleFieldChange(field.id, 'iatacode', e.target.value)} // Update dynamic field
              />
              <TextField
                label="DST"
                variant="outlined"
                fullWidth
                value={field.dstcode}
                onChange={(e) => handleFieldChange(field.id, 'dstcode', e.target.value)} // Update dynamic field
              />
            </Box>
          </Box>
        ))}
        <Button
          sx={{ mt: 2 }}
          variant="outlined"
          fullWidth
          onClick={handleAddFields}
        >
          Collega Nuova
        </Button>

        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleSubmit} // Submit the form on click
        >
          Aggiungi
        </Button>
      </Box>
    </Modal>
  );
}

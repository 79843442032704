import React, { useRef, useMemo, useEffect } from 'react';
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export default function TableApp({ rows, columns, pagination, setHide, contactDetail, handleSort,type,onGridReady,onColumnStateChanged,onEdit, onDelete }) {
  const navigate = useNavigate();

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sortable: true, // Make columns sortable
    };
  }, []);

  const handleRowClicked = (e) => {
    if (!setHide) {
      if (e.data.CodiceUnivoco) {
        navigate(`/hotel-details/${e.data.CodiceUnivoco}`);
      }
    }
    if (contactDetail == 'contact' && e.data.id) {
      navigate(`/contatti-details/${e.data.id}`);
    }
    if (contactDetail == 'negotiation' && e.data.id) {
      navigate(`/negotiation-details/${e.data.id}`);
    }
  }

  const onSortChanged = (params) => {
    // Get all grid columns
    const sortedColumns = params.columnApi.getAllGridColumns().filter((col) => {
      return col.sort; // Check if the column has a sort state
    });

    if (sortedColumns.length > 0) {
      const { colId } = sortedColumns[0];
      const sort = sortedColumns[0].sort;
      handleSort(colId, sort); // Pass the sorted column id and direction to the parent component
    } else {
      handleSort(null, null); // No sorting applied
    }
  };

 
  // const gridOptions = {
  //   columnDefs: columnDefs,
  //   defaultColDef: {
  //     flex: 1,
  //     minWidth: 150,
  //     filter: true,
  //   },
  //   sideBar: "filters",
  // };
  
  const updatedColumns = useMemo(() => {
    const editDeleteColumn = {
      headerName: "Actions",
      cellRenderer: (params) => {
        return (
          <div>
           {/* Edit Button */}
           <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => onEdit(params)}
          >
            <EditIcon />
          </IconButton>

          {/* Delete Button */}
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => onDelete(params)}
          >
            <DeleteIcon />
          </IconButton>
          </div>
        );
      },
      minWidth: 100,
    };

    return [...columns, editDeleteColumn];
  }, [columns, onEdit, onDelete]);

  
  return (
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: 500 }} // the grid will fill the size of the parent container
    >
      <AgGridReact
        rowData={rows}
        columnDefs={updatedColumns}
        defaultColDef={defaultColDef}
        pagination={pagination}
        paginationPageSize={10}
        onRowClicked={handleRowClicked}
        onSortChanged={onSortChanged} // Trigger sorting logic
        onColumnMoved={onColumnStateChanged}
        onColumnVisible={onColumnStateChanged}
        onColumnResized={onColumnStateChanged}
        onColumnPinned={onColumnStateChanged}
        onGridReady={onGridReady} // Restore column state on grid ready
        animateRows={true}
        enableCellChangeFlash={true}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import Stack from "@mui/material/Stack";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import LandingPageSidebar from "../../Components/LandingPageSidebar/LandingPageSidebar";
import LandingPageInfo from "../../Components/LandingPageInfo/LandingPageInfo";
import LandingPageExtra from "../../Components/LandingPageExtra/LandingPageExtra";
import LandingPageHotel from "../../Components/LandingPageHotel/LandingPageHotel";
import useLandingPage from "../../library/hooks/useLandingPage/useLandingPage";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#26529c",
    fontSize: "16px",
    fontWeight: "600",
    "&:hover": {
      color: "#26529c",
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  separator: {
    fontSize: "20px",
    color: "#26529c",
  },
}));

export default function LandingPage() {
  const navigate = useNavigate();
  let { landingPageId, idealVacationId, eventPackageId } = useParams();

  /**
   * Api Context
   */
  const {
    callFetchLandingPageByIdApi,
    callCreateLandingPageApi,
    callUpdateLandingPageApi,
  } = useLandingPage();

  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [sidebarIndex, setSidebarIndex] = useState(0);

  const [formInputs, setFormInputs] = useState({
    id: "-",
    pageURL: "",
    pageTitle: "",
    pageH1: null,
    pageH2: null,
    pageMarkdown: null,
    bannerImages: [],
    initialSearchQuery: null,
    initialSearchStartDate: null,
    initialSearchEndDate: null,
    assignedHotels: [],
    isActive: true,
    createdBy: "-",
    createdDate: new Date().toISOString(),
    tempImage: null,
    selectedImageFileChanged: false,
    pageMarkdown2:""
  });

  const classes = useStyles();

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  /**
   * callback functions
   */

  const getValuesFromInfo = (
    pageUrl,
    pageTitle,
    h1Page,
    h2Page,
    pageMarkdown,
    bannerImage,
    selectedImageFileChanged,
    startDate,
    endDate,
    isSearchable,
    location,
    pageMarkdown2
  ) => {
    setFormInputs({
      ...formInputs,
      pageURL: pageUrl,
      pageTitle: pageTitle,
      pageH1: h1Page,
      pageH2: h2Page,
      pageMarkdown: pageMarkdown,
      bannerImages: selectedImageFileChanged
        ? [bannerImage]
        : formInputs.bannerImages,
      selectedImageFileChanged: selectedImageFileChanged,
      startDate: startDate,
      endDate:endDate,
      isSearchable:isSearchable,
      location:location,
      pageMarkdown2:pageMarkdown2
    });
  };

  const getValuesFromHotel = async (query, hotelList, startDate, endDate) => {
    let newObject = {
      ...formInputs,
      initialSearchQuery: query,
      initialSearchStartDate: startDate != null ? startDate.toISOString() : "",
      initialSearchEndDate: endDate != null ? endDate.toISOString() : "",
      assignedHotels: hotelList,
      locationStr: JSON.stringify(formInputs?.location),
    };
    setFormInputs(newObject);

    // save from api
    await save(newObject);
  };

  /**
   * Create/Update Api call functions
   */

  const save = async (saveObject) => {
    // get image
    let imagefile = null;
    if (saveObject?.selectedImageFileChanged) {
      imagefile = saveObject.bannerImages[0];
    }

    //call Api
    if (saveObject.id === "-") {
      let tempObject = {
        ...saveObject,
        bannerImages: [],
      };
      if (idealVacationId !== null && idealVacationId !== undefined && idealVacationId !== 0) {
        tempObject = { ...tempObject, idealVacationId: idealVacationId };
      }

      if (eventPackageId !== null && eventPackageId !== undefined && eventPackageId !== 0) {
        tempObject = { ...tempObject, eventPackageId: eventPackageId };
      }

      await callCreateLandingPageApi(tempObject, imagefile)
        .then((res) => {
          if (res.isSuccess) {
            navigate(`/hoEscape`);
          } else {
            console.error("Error: Create Landing page", res.message);
          }
        })
        .catch((err) => {
          console.error("Error: Create Landing page", err);
        });
    } else {
      let tempObject = {
        ...saveObject,
        bannerImages: saveObject.selectedImageFileChanged
          ? []
          : saveObject.bannerImages,
      };
      if (idealVacationId != null && idealVacationId !== undefined && idealVacationId !== 0) {
        tempObject = { ...tempObject, idealVacationId: idealVacationId };
      }
      if (eventPackageId !== null && eventPackageId !== undefined && eventPackageId !== 0) {
        tempObject = { ...tempObject, eventPackageId: eventPackageId };
      }
      await callUpdateLandingPageApi(tempObject, imagefile)
        .then((res) => {
          if (res.isSuccess) {
            navigate(`/hoescape`);
          } else {
            console.error("Error: Create Landing page", res.message);
          }
        })
        .catch((err) => {
          console.error("Error: Create Landing page", err);
        });
    }
  };

  const CustomSeparator = () => (
    <Typography variant="body1" className={classes.separator}>
      ›
    </Typography>
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/hoEscape"
      className={classes.link}
    >
      HoEscape
    </Link>,
    <Link underline="hover" key="2" color="inherit" className={classes.link}>
      Create Landing Page
    </Link>,
  ];

  const setupForm = async (data) => {
    if (!data) return;

    //download Image
    let image = null;
    if (data.bannerImages && data.bannerImages?.length > 0) {
      image = await fetch(data.bannerImages[0].imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          return blob;
        })
        .catch((error) => console.error("Error downloading image:", error));
    }

    // assign values to form
    setFormInputs({
      ...data,
      tempImage: image,
    });

    // show form
    setSidebarIndex(1);
  };

  /**
   * side Effects
   */

  useEffect(() => {
    if (landingPageId && landingPageId != 0) {
      callFetchLandingPageByIdApi(landingPageId)
        .then((res) => {
          if (res && res.data) {
            setupForm(res.data);
          } else {
            alert("No record Found!");
            navigate("/landing-page");
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    } else {
      setSidebarIndex(1);
    }
  }, [landingPageId]);

  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Box sx={{ height: "48px", padding: "10px" }}>
        <Stack spacing={2} sx={{ marginLeft: "20px" }}>
          <Breadcrumbs separator={<CustomSeparator />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </Box>

      <Box
        sx={{ backgroundColor: "#f2f2f2", minHeight: "90vh", padding: "40px" }}
      >
        <Box sx={{ display: "flex" }}>
          <LandingPageSidebar index={sidebarIndex} />
          {sidebarIndex === 1 && (
            <LandingPageInfo
              setSidebarIndex={setSidebarIndex}
              sendValueToParent={getValuesFromInfo}
              propPageUrl={formInputs.pageURL}
              propPageTitle={formInputs.pageTitle}
              propH1Page={formInputs.pageH1}
              propH2Page={formInputs.pageH2}
              propPageMarkdown={formInputs.pageMarkdown}
              propBannerImage={formInputs.bannerImages}
              propBannerImageEditCase={formInputs.tempImage}
              propStartDate={formInputs.startDate}
              propEndDate={formInputs.endDate}
              propIsSearchable={formInputs.isSearchable}
              propLocation={formInputs.location}
              propPageMarkdown2 = {formInputs.pageMarkdown2}
            />
          )}
          {/* {sidebarIndex === 2 && (
            <LandingPageExtra setSidebarIndex={setSidebarIndex} />
          )} */}
          {sidebarIndex === 2 && (
            <LandingPageHotel
              setSidebarIndex={setSidebarIndex}
              sendValueToParent={getValuesFromHotel}
              propHotelList={formInputs.assignedHotels}
              propQuery={formInputs.initialSearchQuery}
              propStartDate={
                formInputs.initialSearchStartDate != null &&
                formInputs.initialSearchStartDate != ""
                  ? new Date(formInputs.initialSearchStartDate)
                  : null
              }
              propEndDate={
                formInputs.initialSearchEndDate != null &&
                formInputs.initialSearchEndDate != ""
                  ? new Date(formInputs.initialSearchEndDate)
                  : null
              }
            />
          )}
        </Box>
      </Box>
    </Layout>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import deleteIcon from "../../assets/delete.svg";
import circularIcon from "../../assets/circular.svg";
import leftIcon from "../../assets/Polygon1.svg";
import rightIcon from "../../assets/Polygon2.svg";
import HotelDashboardService from "../../library/services/API/hotelDashboard";
import HotelImagesModel from "../../Pages/HotelImagesModel/HotelImagesModel";
import useHotelDashboard from "../../library/hooks/UserHotelDashboard/useHotelDashboard";

const scrollbarStyles = `
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent; /* make scrollbar transparent */
    }
`;

export default function HotelImages({ dataArr, setDataArr, images }) {
  // const [dataArr, setDataArr] = useState([]);
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [imageDetails, setImageDetails] = useState();
  const updateDataArr = (index, newData) => {
    const updatedDataArr = [...dataArr];
    updatedDataArr[index] = { ...newData };
    setDataArr(updatedDataArr);
  };
  const { dispatchUpdateOrder } = useHotelDashboard();

  useEffect(() => {
    if(dataArr === undefined){
      setDataArr([])
    }else{
      setDataArr(images);
    }
  }, [images]);

  useEffect(() => {
    if(dataArr === undefined){
      setItems([])
    }
    else{
      setItems(dataArr);
    }
  }, [dataArr]);

  const handleSwap = (currentIndex, direction) => {
    const newIndex = direction === "left" ? currentIndex - 1 : currentIndex + 1;
    if (newIndex >= 0 && newIndex < items.length) {
      const updatedItems = [...items];
      const temp = updatedItems[currentIndex];
      updatedItems[currentIndex] = updatedItems[newIndex];
      updatedItems[newIndex] = temp;
      // setItems(updatedItems);
      setDataArr(updatedItems);
      const payload = updatedItems?.map((item, index) => ({
        order: index + 1,
        id: item.id,
      }));
      dispatchUpdateOrder({ orderRequests: payload });
    }
  };

  const handleAddButton = () => {
    setIsModalOpen(true);
    setIsEdit(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setImageDetails();
    setIsEdit(false);
  };

  const handleDeleteImage = async (index, imageId) => {
    if (window.confirm("Vuoi rimuovere questa immagine? (la modifica verrà effettuata solo al click del pulsante salva alla fine)")) {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
      updateDataArr(updatedItems);
      setDataArr(updatedItems);
    }
  };
  const handleEditImage = (index, item) => {
    setImageDetails(item);
    setEditIndex(index);
    setIsModalOpen(true);
    setIsEdit(true);
  };
  return (
    <>
      <style>{scrollbarStyles}</style>
      <Box sx={{ overflowX: "auto", overflowY: "hidden", borderRadius: "5px" }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginLeft: "30px", display: "flex" }}>
            <Button
              sx={{
                marginLeft: "-20px",
                width: "317px",
                height: "147px",
                border: "1px dashed gray",
                borderWidth: "2px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
              }}
              onClick={handleAddButton}
            >
              <Typography
                sx={{
                  color: "black",
                  textDecoration: "underline",
                  fontSize: "26px",
                  fontWeight: "600",
                  textTransform: "none",
                }}
              >
                Carica Immagine
              </Typography>
            </Button>
            {items?.map((item, index) => (
              <Box sx={{ display: "flex", position: "relative" }} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: "10px",
                      height: "31px",
                      position: "relative",
                      right: "-27px",
                      fontSize: "24px",
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    {index + 1}
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        position: "absolute",
                        left: "34px",
                        top: "115px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSwap(index, "left")}
                    >
                      <img src={leftIcon} alt="img" />
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        left: "58px",
                        top: "115px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSwap(index, "right")}
                    >
                      <img src={rightIcon} alt="img" />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "489px",
                    height: "147px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "32px",
                    fontWeight: "600",
                    textDecoration: "underline",
                    flexShrink: 0,
                    maxWidth: "250px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={item?.imageUrl}
                    alt={item?.altText ?? 0}
                    style={{ width: "250px", height: "147px" }}
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "6px",
                    right: "43px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEditImage(index, item)}
                >
                  <img src={circularIcon} alt="img" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "6px",
                    right: "12px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="img"
                    onClick={() => handleDeleteImage(index, item?.id)}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {isModalOpen && (
        <HotelImagesModel
          onClose={handleCloseModal}
          updateDataArr={updateDataArr}
          index={editIndex}
          item={items[editIndex]}
          imageDetails={imageDetails}
          isEdit={isEdit}
          setDataArr={setDataArr}
          dataArr={dataArr}
        />
      )}
    </>
  );
}

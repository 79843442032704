import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import deleteIcon from "../../assets/Vector.svg";
import HotelDashboardService from "../../library/services/API/hotelDashboard";
import { useParams } from "react-router-dom";

export default function HotelImagesModel({
  onClose,
  updateDataArr,
  index,
  dataArr,
  imageDetails,
  isEdit,
  setDataArr,
}) {
  let { code } = useParams();
  const handleImageName = (imageUrl) =>{
    if(imageUrl != null){
      const pathToCheck = "Upload/HISAVCOLEL";
      if (imageUrl.includes(pathToCheck)) {
        const parts = imageUrl.split('/');
       return parts[parts.length - 1];
      }
    }
  }

  const [multiImg,setMultiImg]= useState([]);

  useEffect(()=>{
    const responses = [];
    if(isEdit){
      responses.push({ imageUrl: imageDetails.imageUrl, imageName: imageDetails.imageName });
      setMultiImg(responses);
    }
  },[imageDetails])

  useEffect(()=>{
    if(dataArr === undefined){
      setDataArr([]);
    }
  },[dataArr])


  const [error, setError] = useState({
    imageUrl: false,
  });
  const [values, setValues] = useState({
    imageUrl: imageDetails?.imageUrl ? imageDetails?.imageUrl : null,
    imageName: imageDetails?.imageName ? imageDetails?.imageName : handleImageName(imageDetails?.imageUrl),
    order: imageDetails?.order ? imageDetails?.order : null,
    alt: imageDetails?.alt ? imageDetails?.alt : "",
    titleMetaTag: imageDetails?.titleMetaTag ? imageDetails?.titleMetaTag : "",
    tagMetaTag: imageDetails?.tagMetaTag ? imageDetails?.tagMetaTag : "",
    authorMetaTag: imageDetails?.authorMetaTag
      ? imageDetails?.authorMetaTag
      : "",
    copyRightMetaTag: imageDetails?.copyRightMetaTag
      ? imageDetails?.copyRightMetaTag
      : "",
    redirectUrl: imageDetails?.redirectUrl ? imageDetails?.redirectUrl : "",
  });
  const validateImageDetails = () => {
    let isError = false;
    if (!values?.imageUrl && multiImg.length == 0) {
      setError((prevState) => ({ ...prevState, imageUrl: true }));
      isError = true;
    }

    return !isError;
  };


  const handleSave = async () => {
    if (!validateImageDetails()) return;
    if (isEdit) {
    
      const updateBannerImage = {
        ...values,
        id: imageDetails?.id,
        imageUrl: values?.imageUrl,
        alt: values?.alt != "" ? values?.alt : 0
      };

      console.log(updateBannerImage);
      updateDataArr(index,updateBannerImage);

      // setDataArr((prevDataArr) => {
      //   return prevDataArr.map((item) =>
      //     item.id === updateBannerImage.id ? updateBannerImage : item
      //   );
      // });

      // function updateDataArr(dataArr, updateBannerImage) {
      //   dataArr.push(updateBannerImage);
      //   return dataArr;
      // }

      // updateDataArr(dataArr, updateBannerImage);
      onClose();
    } else {
      if(multiImg.length > 0){
        multiImg.forEach((img, index) => {
        const bannerImage = {
          imageUrl: img?.imageUrl,
  
          imageName: img?.imageName,
          order: values?.order,
          authorMetaTag:values?.authorMetaTag,
          copyRightMetaTag:values?.copyRightMetaTag,
          titleMetaTag:values?.titleMetaTag,
          alt:values?.alt,
          altText: 0,
          metaData: {
            title: values?.titleMetaTag,
            description: values?.tagMetaTag,
          },
          redirectLink: values?.redirectUrl, 
        };
        function CreateData(dataArr, bannerImage) {
          dataArr.push(bannerImage);
          return dataArr;
        }
        CreateData(dataArr, bannerImage);
      onClose();
      }
        )};
    }
  };

  // const handleImageChange = (event) => {
  //   debugger
  //   console.log(event.target.files)
  //   const fileList = event.target.files[0];
  //   if (fileList) {
  //     const fileType = fileList.type;
  //     if (fileType !== "image/webp") {
  //       alert("Please select a webp image.");
  //       event.target.value = null;
  //       return;
  //     }
  //     const fetchData = async () => {
  //       try {
  //         const formData = new FormData();
  //         formData.append("File", fileList);
  //         formData.append("HotelCode", code);
  //         const response = await HotelDashboardService.uploadHotelImage(
  //           formData
  //         );
  //         setValues((prevState) => ({
  //           ...prevState,
  //           imageUrl: response,
  //           imageName: fileList?.name,
  //         }));
  //       } catch (error) {
  //         console.error("Error fetching hotel banner:", error);
  //       }
  //     };

  //     fetchData();
  //   }
  // };


  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileType = file.type;
            if (fileType !== "image/webp") {
                alert("Please select only webp images.");
                event.target.value = null;
                return;
            }
        }
        const uploadImages = async () => {
            try {
                const responses = [];
                const totelFile = files.length;
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const formData = new FormData();
                    formData.append("File", file);
                    formData.append("HotelCode", code);

                    const response = await HotelDashboardService.uploadHotelImage(formData);
                    responses.push({ imageUrl: response, imageName: file.name });
                 
                    if(isEdit){
                      setValues((prevState) => ({
                        ...prevState,
                        imageUrl: response,
                        imageName: file.name,
                      }));
                    }
                }
                setMultiImg(responses);
            } catch (error) {
                console.error("Error uploading hotel images:", error);
            }
        };

        uploadImages();
    }
};

  const removeImage = (index) => {
    // setValues((prevState) => ({
    //   ...prevState,
    //   imageUrl: null,
    //   imageName: "",
    // }));
    // setMultiImg([]);
    setMultiImg(multiImg.filter((_, i) => i !== index));
  };

  const handleChange = (event, field) => {
    setValues((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));

    if (event.target.value) {
      setError((prevState) => ({
        ...prevState,
        [field]: false,
      }));
    }
  };

  function checkImgURL(imageUrl) {
    return typeof imageUrl === "string"
      ? imageUrl
      : URL.createObjectURL(imageUrl);
  }
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "1000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "712px",
          height: "700px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "80px",
            backgroundColor: "#26529c",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <div
            style={{
              fontSize: "32px",
              fontWeight: "600",
              marginLeft: "20px",
              paddingTop: "12px",
              color: "white",
            }}
          >
            {isEdit ? " Modifica Banner" : " Creare Banner"}
          </div>
          <div
            style={{
              cursor: "pointer",
              marginRight: "20px",
              paddingTop: "20px",
            }}
          >
            <CloseIcon
              fontSize="large"
              onClick={onClose}
              sx={{ color: "white" }}
            />
          </div>
        </div>
        <div style={{ padding: "20px", height: "640px", overflowY: "auto" }}>
          <div
            style={{
              display: "contents",
              overflowX: "auto",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            {multiImg.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "10px",
                  overflowX: 'scroll',
                  
                }}
              >
                {multiImg.map((imgValue, index) => (
                  <div style={{position:"relative", marginRight:"24px"}}>               
                  <div key={index} style={{ width: "24px", position:"absolute", top:"5px", right:"30px" }}>
                  <Button
                    onClick={() => removeImage(index)}
                    sx={{
                      position: "unset",
                      top: "3px",
                      right: "-225px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete"
                      style={{
                        backgroundColor: "white",
                        width: "24px",
                        height: "24px",
                        padding: "4px",
                        borderRadius: "10px",
                      }}
                    />
                  </Button>
                </div>
                <img
                  src={checkImgURL(imgValue.imageUrl)}
                  // src={values.imageUrl}
                  alt={`Image-${imgValue.imageName}`}
                  style={{
                    maxWidth: "250px",
                    height: "147px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                </div>
              ))}
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "712px",
                    flexDirection: "column",
                  }}
                >
                  <button
                    style={{
                      width: "489px",
                      height: "147px",
                      border: "1px dashed black",
                      borderRadius: "10px",
                      textDecoration: "underline",
                      fontSize: "32px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    <label
                      htmlFor="image-upload"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Add an Image
                    </label>
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/*"
                      multiple={!isEdit}
                      style={{ display: "none" }}
                      onChange={(e) => handleImageChange(e)}
                      // onChange={(e) => handleFileChange(e)}
                    />
                  </button>
                  {error.imageUrl && (
                    <div className="error-message">
                      {"Image is required field."}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>{!isEdit ? "Nome File Multiplo": "Nome File" } </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.imageName}
              onChange={(event) => handleChange(event, "imageName")}
              placeholder="Image Name"
              disabled
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Alt Text (Max 100 Caratteri)
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.alt}
              onChange={(event) => handleChange(event, "alt")}
              placeholder="Alt"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
            Title MetaTag
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.titleMetaTag}
              onChange={(event) => handleChange(event, "titleMetaTag")}
              placeholder="Title"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
            Author MetaTag
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.authorMetaTag}
              onChange={(event) => handleChange(event, "authorMetaTag")}
              placeholder="Author"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Copyright MetaTag
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.copyRightMetaTag}
              onChange={(event) => handleChange(event, "copyRightMetaTag")}
              placeholder="Copyright"
            />
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Meta Data description
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.tagMetaTag}
              onChange={(event) => handleChange(event, "tagMetaTag")}
              placeholder="Tag"
            />
          </div> */}

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Redirect Link
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.redirectUrl}
              onChange={(event) => handleChange(event, "redirectUrl")}
              placeholder="Url"
            />
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              borderTop: "1px solid #ccc",
            }}
          >
            <Button
              variant="contained"
              sx={{
                height: "53px",
                width: "100%",
                backgroundColor: "#26529C",
                "&:hover": {
                  backgroundColor: "#26529C",
                },
              }}
              onClick={handleSave}
            >
              Salva
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

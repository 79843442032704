import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { useMemo, useState } from "react";
import "../../App.css";
import img from "../../assets/img1.png";
import Layout from "../../Components/Layout/Layout";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Pagination, Dialog } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../Components/CustomFilter/CustomFilter";
import useHotelDashboard from "../../library/hooks/UserHotelDashboard/useHotelDashboard";
import { useHotelsDashboardContext } from "../../library/store/slices/HotelsDashboardSlicer";
import { useHotelsDashboardFilterContext } from "../../library/store/slices/HotelsDashboardFilterSlicer";
import TableApp from "../../Components/TableApp/TableApp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as CrossIcon } from "assets/icons/Cross.svg";
import PrimaryButton from "Components/Button/PrimaryButton";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover": {
      color: "#26529C",
    },
  },
  icon: {
    fill: "#26529C",
  },
  menuItem: {
    color: "#26529C",
    "&:hover": {
      backgroundColor: "#e3e9f2",
    },
  },
}));
export default function MainPage() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    firstValue: "Assegnatario Contatti",
    secondValue: "Data Creazione",
    thirdValue: "Ultima Attività",
    fourthValue: "Statistiche Da:",
  });

  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [addHotel, setAddHotel] = useState(false);
  const [hotelCode, setHotelCode] = useState(null);
  const [hotelName, setHotelName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dstCode, setDstCode] = useState(null);
  const [dstName, setDstName] = useState(null);
  const [nationCode, setNationCode] = useState(null);
  const [nationName, setNationName] = useState(null);
  const [iataCode, setIataCode] = useState(null);


  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  const {
    callUpdateHotelApi,
  } = useHotelDashboard();

  const classes = useStyles();

  const handleChange = (event, field) => {
    setValues((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };
  const [tuttiRows, setTuttiRows] = useState([]);

  // const columns = [
  //   {
  //     field: "image",
  //     headerName: " ",
  //     width: 70,

  //     editable: true,
  //     renderCell: (params) => (
  //       <img src={params.value} alt="Hotel" style={{ width: 50, height: 50 }} />
  //     ),
  //   },
  //   { field: "name", headerName: "Nome Hotel", width: 250, editable: false },
  //   {
  //     field: "hotel",
  //     headerName: "Codice Hotel",
  //     width: 250,
  //     editable: false,
  //   },
  //   {
  //     field: "clicks",
  //     headerName: "Numero di Click",
  //     width: 250,
  //     editable: true,
  //   },
  //   {
  //     field: "Eta",
  //     headerName: "Età",
  //     width: 250,
  //     editable: true,
  //   },
  //   {
  //     field: "origin",
  //     headerName: "Luogo di Origine",
  //     width: 270,
  //     editable: true,
  //   },
  // ];

  // const columns = [
  //   {
  //     field: "image",
  //     flex: 1,
  //     cellRenderer: (params) => {
  //       console.log("cell", params);
  //       // Render the image element within the cell
  //       return (
  //         <img
  //           src={params.value}
  //           alt="Image"
  //           style={{ maxWidth: "100%", height: "auto" }}
  //         />
  //       );
  //     },
  //   }, //This column will be twice as wide as the others
  //   { field: "name", flex: 1 },
  //   { field: "hotel", flex: 1 },
  //   { field: "clicks", flex: 1 },
  //   { field: "Eta", flex: 1 },
  //   { field: "origin", flex: 1 },
  // ];

  const [columnVisibility, setColumnVisibility] = useState({
    Copertina: false,
    Nome: false,
    Descrizione: false,
    Priorità: false,
    CodiceUnivoco: false,
    CodiceIATA: false,
    Città: false,
    Provincia: false,
    Regione: false,
    NumeroVisitatori: false,
    NumeroRichiestePreventivi: false,
    NumerodiPrenotazioni: false,
    RatioPreventivisuVisitatori: false,
    RatioPrenotazionisuVisitatori: false,
    RatioPrenotazionisuPreventivi: false,
  });

  const hideColumns = [
    "Copertina",
    "Nome",
    "Descrizione",
    "Priorità",
    "CodiceUnivoco",
    "CodiceIATA",
    "Città",
    "Provincia",
    "Regione",
    "NumeroVisitatori",
    "NumeroRichiestePreventivi",
    "NumerodiPrenotazioni",
    "RatioPreventivisuVisitatori",
    "RatioPrenotazionisuVisitatori",
    "RatioPrenotazionisuPreventivi",
  ];

  const handleColumnVisibilityChange = (event) => {
    const columnName = event.target.value;
    const isChecked = event.target.checked;

    setColumnVisibility({
      ...columnVisibility,
      [columnName]: isChecked,
    });
  };

  const columns = [
    {
      field: "Copertina",
      headerName: "Copertina",
      hide: columnVisibility.Copertina,
      minWidth: 150,
      cellRenderer: (params) => {
        return (
          <img
            src={params?.data?.Copertinal}
            alt="Image"
            style={{ maxWidth: "25%", height: "auto" }}
          />
        );
      },
    },
    {
      field: "Descrizione",
      headerName: "Nome",
      minWidth: 150,
      hide: columnVisibility.Descrizione
    },
    // {
    //   field: "Descrizione",
    //   headerName: "Nome columns",
    //   minWidth: 200,
    //   hide: columnVisibility.Descrizione
    // },
    {
      field: "Priorità",
      headerName: "Priorità",
      minWidth: 150,
      hide: columnVisibility.Priorità,
      filter: "agNumberColumnFilter",
    },
    {
      field: "CodiceUnivoco",
      headerName: "Codice Univoco",
      hide: columnVisibility.CodiceUnivoco,
    },
    {
      field: "CodiceIATA",
      headerName: "Codice IATA",
      hide: columnVisibility.CodiceIATA,
    },
    {
      field: "Città",
      headerName: "Città",
      minWidth: 150,
      hide: columnVisibility.Città,
    },
    {
      field: "Regione",
      headerName: "Regione ",
      minWidth: 150,
      hide: columnVisibility.Regione,
    },
    {
      field: "NumeroVisitatori",
      headerName: "Numero Visitatori ",
      minWidth: 150,
      hide: columnVisibility.NumeroVisitatori,
      filter: "agNumberColumnFilter",
    },
    {
      field: "NumeroRichiestePreventivi",
      headerName: "Numero Richieste Preventivi ",
      minWidth: 150,
      hide: columnVisibility.NumeroRichiestePreventivi,
      filter: "agNumberColumnFilter",
    },
    {
      field: "NumerodiPrenotazioni",
      headerName: "Numero di Prenotazioni ",
      minWidth: 150,
      hide: columnVisibility.NumerodiPrenotazioni,
      filter: "agNumberColumnFilter",
    },
    {
      field: "RatioPreventivisuVisitatori",
      headerName: "Ratio Preventivi su Visitatori",
      minWidth: 150,
      hide: columnVisibility.RatioPreventivisuVisitatori,
      filter: "agNumberColumnFilter",
    },
    {
      field: "RatioPrenotazionisuVisitatori",
      headerName: "Ratio Prenotazioni su Visitatori",
      minWidth: 150,
      hide: columnVisibility.RatioPrenotazionisuPreventivi,
      filter: "agNumberColumnFilter",
    },
    {
      field: "RatioPrenotazionisuPreventivi",
      headerName: "Ratio Prenotazioni su Preventivi",
      minWidth: 150,
      hide: columnVisibility.RatioPrenotazionisuPreventivi,
      filter: "agNumberColumnFilter",
    },
  ];

  const { dispatchFetchAllHotels, dispatchFetchAllHotelsByFilter } = useHotelDashboard();
  const { state: hotelsResponse } = useHotelsDashboardContext();

  const { state: hotelsFilterResponse } = useHotelsDashboardFilterContext();


  // useMemo(() => {
  //   const formatHotelData = (hotels) => {
  //     const hotelDetails = [];
  //     let hotel = {};
  //     let displayImage = "";
  //     let displayImageUrl = "";
  //     const api2Url = process.env.REACT_APP_API_URL;
  //     for (const property in hotels) {
  //       hotel = hotels[property];
  //       displayImage = hotel.images
  //         ? hotel.images.find((x) => x.order === 1)
  //         : "";
  //       displayImageUrl = displayImage ? displayImage.imageUrl : "";
  //       if(displayImageUrl != null && displayImageUrl != "" && displayImageUrl != undefined){
  //         const imgSplit =  displayImage.imageUrl.split("/Upload");
  //         if(imgSplit != null && imgSplit.length > 1){
  //           displayImageUrl = api2Url + "/Upload" + imgSplit[1];
  //         }
  //       };

  //       hotelDetails.push({
  //         id: hotel.id,
  //         Copertinal: displayImageUrl,
  //         Nome: hotel?.title || "",
  //         Priorità: hotel.priorityField,
  //         CodiceIATA: hotel.locationToLink ? hotel.locationToLink.iataCode : "",
  //         Regione: hotel?.locationToLink?.locality,
  //         NumeroVisitatori: "--",
  //         NumerodiPrenotazioni: "--",
  //         RatioPreventivisuVisitatori: "--",
  //         RatioPrenotazionisuVisitatori: "--",
  //         RatioPrenotazionisuPreventivi: "--",
  //         CodiceUnivoco:hotel.hotelCode,
  //         Città: hotel?.locationToLink?.dstName,
  //       });
  //     }

  //     setTuttiRows(hotelDetails);
  //   };
  //   if (hotelsResponse) {
  //     formatHotelData(hotelsResponse?.items);
  //   }
  // }, [hotelsResponse]);

  useMemo(() => {
    const formatHotelFilterData = (hotelData) => {
      if (hotelData != undefined) {
        const hotelDetails = [];
        let hotel = {};
        let displayImage = "";
        let displayImageUrl = "";
        const api2Url = process.env.REACT_APP_API_URL;
        for (const property in hotelData) {
          hotel = hotelData[property].hotelDetailExtension;
          if (hotel != undefined) {
            displayImage = hotel.images
              ? hotel.images.find((x) => x.order === 1)
              : "";
            displayImageUrl = displayImage ? displayImage.imageUrl : "";
            if (displayImageUrl != null && displayImageUrl != "" && displayImageUrl != undefined) {
              const imgSplit = displayImage.imageUrl.split("/Upload");
              if (imgSplit != null && imgSplit.length > 1) {
                displayImageUrl = api2Url + "/Upload" + imgSplit[1];
              }
            }
          };
          hotelDetails.push({
            id: hotel?.id,
            Copertinal: displayImageUrl,
            Nome: hotel?.title || "",
            Descrizione: hotelData[property].description || "",
            Priorità: hotel?.priorityField,
            CodiceIATA: hotel?.locationToLink ? hotel.locationToLink.iataCode : "",
            Regione: hotel?.locationToLink?.locality,
            NumeroVisitatori: "--",
            NumerodiPrenotazioni: "--",
            RatioPreventivisuVisitatori: "--",
            RatioPrenotazionisuVisitatori: "--",
            RatioPrenotazionisuPreventivi: "--",
            CodiceUnivoco: hotel != null ? hotel?.hotelCode : hotelData[property].objCode,
            Città: hotel?.locationToLink?.dstName,
          });
        }
        setTuttiRows(hotelDetails);
      };
    }
    if (hotelsFilterResponse != null && hotelsFilterResponse?.data != null) {
      const finalHotalData = hotelsFilterResponse.data; //hotelsFilterResponse.data.filter(x=>x.hotelDetailExtension);
      formatHotelFilterData(finalHotalData);
    }
  }, [hotelsFilterResponse])

  const [activeButton, setActiveButton] = useState("Tutti");

  // useEffect(() => {
  //   const body = { skip, take };
  //    //dispatchFetchAllHotels(body);
  //   dispatchFetchAllHotelsByFilter("ALL")
  // }, [skip, take]);

  useEffect(() => {
    dispatchFetchAllHotelsByFilter("ALL")
  }, [])

  const handleTuttiClick = () => {
    setActiveButton("Tutti");
    dispatchFetchAllHotelsByFilter("ALL")
  };

  const handleNostriClick = () => {
    setActiveButton("Nostri");
    dispatchFetchAllHotelsByFilter("Our")
  };

  const handle1way2Click = () => {
    setActiveButton("1way2");
    dispatchFetchAllHotelsByFilter("External")
  };

  const handleImpostazioni = () => {
    navigate("/settings");
  };

  const handleOpenAddHotel = () => {
    setAddHotel(true);
  }
  const handleDialogClose = () => {
    setAddHotel(false);
  }



  const handleSalvaClick = async () => {
    if (hotelCode == null && hotelName == null && dstCode == null && nationCode == null) {
      return;
    }
    setIsLoading(true);

    const locationToLink ={
      dstCode:dstCode,
      nationCode:nationCode,
      dstName:dstName,
      nationName:nationName,
      iataCode:iataCode  
    }
    const bodyData = {
      hotelCode: hotelCode,
      description: hotelName,
      locationToLink: locationToLink
    };

    await callUpdateHotelApi(bodyData)
      .then((res) => {
        toast.success("Le modifiche sono state salvate");
        handleDialogClose();
        dispatchFetchAllHotelsByFilter("ALL")
      })
      .catch((e) => {
        toast.error(`Error while updating data : ${e}`);
        console.warn("Error while updating data :", e);
      });

    setIsLoading(false);
  };

  const onPageChange = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
    const newSkip = (pageNumber - 1) * take;
    setSkip(newSkip);
  };
  const toPage = skip + take;
  const totalRecords = hotelsResponse?.totalRecords;
  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Box sx={{ marginLeft: "50px", marginRight: "50px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography
              variant="body1"
              fontWeight="bold"
              fontSize="40px"
              lineHeight="50px"
              color="#26529C"
              sx={{ paddingTop: "30px" }}
            >
              Hotel
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                border: "1px solid #26529C",
                borderRadius: "30px",
                color: "#26529C",
                marginLeft: "10px",
                marginTop: "34px",
                height: "53px",
                width: "200px",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: "600",
              }}
              onClick={handleImpostazioni}
            >
              Impostazioni
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", marginTop: "60px" }}>
          <Box sx={{ borderBottom: "2px solid #26529c", width: "80px" }}></Box>
          <Box>
            <Button
              variant="outlined"
              sx={{
                height: "63px",
                width: "243px",
                borderTopRightRadius: "0px",
                borderTopLeftRadius: "30px",
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                backgroundColor: activeButton === "Tutti" ? "white" : "#e9eef6",
                borderWidth:
                  activeButton === "Tutti"
                    ? "2px 1px 0px 2px"
                    : "2px 1px 2px 2px",
                borderColor: "#26529c",
                "&:hover": {
                  borderWidth:
                    activeButton === "Tutti"
                      ? "2px 1px 0px 2px"
                      : "2px 1px 2px 2px",
                  borderColor: "#26529c",
                },
              }}
              onClick={handleTuttiClick}
            >
              Tutti gli Hotel
            </Button>
            <Button
              variant="outlined"
              sx={{
                height: "63px",
                width: "243px",
                borderRadius: "0px",
                backgroundColor:
                  activeButton === "Nostri" ? "white" : "#e9eef6",
                borderWidth:
                  activeButton === "Nostri" ? "2px 1px 0px 1px" : "2px 1px",
                borderColor: "#26529c",
                "&:hover": {
                  borderWidth:
                    activeButton === "Nostri" ? "2px 1px 0px 1px" : "2px 1px",
                  borderColor: "#26529c",
                },
              }}
              onClick={handleNostriClick}
            >
              Hotel Nostri
            </Button>
            <Button
              variant="outlined"
              sx={{
                height: "63px",
                width: "243px",
                backgroundColor: activeButton === "1way2" ? "white" : "#e9eef6",
                borderWidth:
                  activeButton === "1way2"
                    ? "2px 2px 0px 1px"
                    : "2px 2px 2px 1px",
                borderTopRightRadius: "30px",
                borderTopLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderColor: "#26529c",
                "&:hover": {
                  borderWidth:
                    activeButton === "1way2"
                      ? "2px 2px 0px 1px"
                      : "2px 2px 2px 1px",
                  borderColor: "#26529c",
                },
              }}
              onClick={handle1way2Click}
            >
              Hotel 1way2
            </Button>
          </Box>

          <Box sx={{ borderBottom: "2px solid #26529c", flex: 1 }}></Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              placeholder="Cerca per codice o nome"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: {
                  marginTop: "34px",
                  backgroundColor: "#f1f4f9",
                  width: "255px",
                  height: "42px",
                  borderRadius: "5px",
                },
              }}
            />

            <Box sx={{ marginTop: "34px" }}>
              <Select
                value={values.firstValue}
                onChange={(e) => handleChange(e, "firstValue")}
                inputProps={{ "aria-label": "Select Assegnatario Contatti" }}
                classes={{ select: classes.select, icon: classes.icon }}
                sx={{
                  marginLeft: "10px",
                  height: "42px",
                  color: "#26529C",
                  border: "0px",
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
              >
                <MenuItem
                  value="Assegnatario Contatti"
                  sx={{ color: "#26529C" }}
                >
                  Assegnatario Contatti
                </MenuItem>
                <MenuItem value="value1" sx={{ color: "#26529C" }}>
                  Value 1
                </MenuItem>
                <MenuItem value="value2" sx={{ color: "#26529C" }}>
                  Value 2
                </MenuItem>
                <MenuItem value="value3" sx={{ color: "#26529C" }}>
                  Value 3
                </MenuItem>
              </Select>

              <Select
                value={values.secondValue}
                onChange={(e) => handleChange(e, "secondValue")}
                inputProps={{ "aria-label": "Select Data Creazione" }}
                classes={{ select: classes.select, icon: classes.icon }}
                sx={{
                  marginLeft: "10px",
                  height: "42px",
                  color: "#26529C",
                  border: "0px",
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
              >
                <MenuItem value="Data Creazione" sx={{ color: "#26529C" }}>
                  Data Creazione
                </MenuItem>
                <MenuItem value="value1" sx={{ color: "#26529C" }}>
                  Value 1
                </MenuItem>
                <MenuItem value="value2" sx={{ color: "#26529C" }}>
                  Value 2
                </MenuItem>
                <MenuItem value="value3" sx={{ color: "#26529C" }}>
                  Value 3
                </MenuItem>
              </Select>

              <Select
                value={values.thirdValue}
                onChange={(e) => handleChange(e, "thirdValue")}
                inputProps={{ "aria-label": "Select Ultima Attività" }}
                classes={{ select: classes.select, icon: classes.icon }}
                sx={{
                  marginLeft: "10px",
                  height: "42px",
                  color: "#26529C",
                  border: "0px",
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
              >
                <MenuItem value="Ultima Attività" sx={{ color: "#26529C" }}>
                  Ultima Attività
                </MenuItem>
                <MenuItem value="value1" sx={{ color: "#26529C" }}>
                  Value 1
                </MenuItem>
                <MenuItem value="value2" sx={{ color: "#26529C" }}>
                  Value 2
                </MenuItem>
                <MenuItem value="value3" sx={{ color: "#26529C" }}>
                  Value 3
                </MenuItem>
              </Select>
              <Select
                value={values.fourthValue}
                onChange={(e) => handleChange(e, "fourthValue")}
                inputProps={{ "aria-label": "Select Statistiche Da:" }}
                classes={{ select: classes.select, icon: classes.icon }}
                sx={{
                  marginLeft: "10px",
                  height: "42px",
                  color: "#26529C",
                  border: "0px",
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
              >
                <MenuItem value="Statistiche Da:" sx={{ color: "#26529C" }}>
                  Statistiche Da:
                </MenuItem>
                <MenuItem value="value1" sx={{ color: "#26529C" }}>
                  Value 1
                </MenuItem>
                <MenuItem value="value2" sx={{ color: "#26529C" }}>
                  Value 2
                </MenuItem>
                <MenuItem value="value3" sx={{ color: "#26529C" }}>
                  Value 3
                </MenuItem>
              </Select>
            </Box>
          </Box> */}
          <Box sx={{ marginTop: "30px", display: "flex", justifyContent: "space-between" }}>
            <CustomFilter
              hideColumns={hideColumns}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
              columnVisibility={columnVisibility}
            />
            <button
              className="text-[20px] font-semibold text-primary placeholder:text-primary border-[1.5px] border-primary rounded-[5px] w-[150px] h-[42px] bg-primary bg-opacity-[0.13] pl-[17px] pr-[10px] cursor-pointer"
              onClick={handleOpenAddHotel}
            >
              Aggiungere
            </button>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            marginBottom: "50px",
            minWidth: "100%",
            overflowX: "auto",
          }}
        >
          <TableApp rows={tuttiRows} columns={columns} pagination={true} />
          {/* {activeButton === "Tutti" && (
            <div>
              <TableApp
                rows={tuttiRows}
                columns={columns}
                take={take}
                skip={skip}
                setSkip={setSkip}
                setTake={setTake}
                currentPageNumber={hotelsResponse?.currentPageNumber}
                recordsPerPage={hotelsResponse?.recordsPerPage}
                totalPages={hotelsResponse?.totalPages}
                totalRecords={hotelsResponse?.totalRecords}
              />
              <div className="pagination_menu">
                <div className="page_size_part">
                  <span style={{ fontSize: "14px" }}>Page Size:</span>
                  <Select
                    value={take}
                    onChange={(e) => {
                      setTake(e.target.value);
                      setCurrentPageNumber(1);
                      setSkip(0);
                    }}
                    inputProps={{
                      "aria-label": "Select Assegnatario Contatti",
                    }}
                    classes={{ select: classes.select, icon: classes.icon }}
                    sx={{
                      marginLeft: "10px",
                      height: "25px",
                      width: "70px",
                      "&.MuiSelect-select .makeStyles-select-16 .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input ":
                        { padding: "10px" },
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </div>
                <div className="pagination_count pagination_font">
                  <span style={{ fontSize: "14px" }}>
                    <span style={{ fontWeight: "600" }}>{skip + 1}</span>
                    {` to `}
                    <span style={{ fontWeight: "600" }}>
                      {toPage > totalRecords ? totalRecords : toPage}
                    </span>
                    {` of `}
                    <span style={{ fontWeight: "600" }}>{totalRecords}</span>
                    {` `}
                  </span>
                </div>
                <div className="pagination_count pagination_font">
                  <button
                    disabled={currentPageNumber === 1}
                    onClick={() => onPageChange(currentPageNumber - 1)}
                  >
                    <ChevronLeftIcon />
                  </button>
                  <span style={{ fontSize: "14px" }}>
                    {` Page `}
                    <span style={{ fontWeight: "600" }}>
                      {currentPageNumber}
                    </span>
                    {` of `}
                    <span style={{ fontWeight: "600" }}>
                      {Math.ceil(totalRecords / take)}
                    </span>
                    {` `}
                  </span>
                  <button
                    disabled={
                      currentPageNumber === Math.ceil(totalRecords / take)
                    }
                    onClick={() => onPageChange(currentPageNumber + 1)}
                  >
                    <ChevronRightIcon />
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeButton === "Nostri" && (
            <TableApp rows={nostriRows} columns={columns} />
          )}
          {activeButton === "1way2" && (
            <TableApp rows={thirdRows} columns={columns} />
          )} */}
        </Box>
        {addHotel &&
          (
            <Dialog
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "520px",
                    borderRadius: "8px",
                    paddingTop: "22px",
                    paddingBottom: "25px",
                  },
                },
              }}
              open={addHotel}
              onClose={handleDialogClose}
            >
              <div className="flex items-center justify-between pl-[33px] pr-[25px]">
                <h2 className="text-[24px] font-semibold text-[#2E2C34]">
                  {"Crea Nuovo Hotel"}
                </h2>
                <CrossIcon onClick={handleDialogClose} style={{ cursor: "pointer" }} />
              </div>
              <Divider sx={{ color: "#E3E3E3", marginTop: "5px" }} />

              <Box
              sx={{
                p: 2,
              }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    gap: 2,
                  }}
                >
                  <div>
                    {/* <label className="text-[14px] font-medium text-[#1E1E1E]">Codice Hotel</label> */}
                    <TextField
                      label="Codice Hotel"
                      required
                      fullWidth
                      onChange={(e) => setHotelCode(e.target.value)}
                      value={hotelCode}
                      sx={{
                        marginTop: "8px",
                        //width: "90%",
                        //height: "20px",
                        borderRadius: "8px",
                      }}
                      variant="outlined"
                      placeholder="Inserisci il codice del hotel"
                    />
                  </div>
                  <div>
                    {/* <label className="text-[14px] font-medium text-[#1E1E1E]"></label> */}
                    <TextField
                      label="Nome Hotel"
                      required
                      fullWidth
                      onChange={(e) => setHotelName(e.target.value)}
                      value={hotelName}
                      sx={{
                        marginTop: "8px",
                        borderRadius: "8px",
                      }}
                      variant="outlined"
                      placeholder="Inserisci il nome del hotel"
                    />
                  </div>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <div>
                    {/* <label className="text-[14px] font-medium text-[#1E1E1E]">IATA</label> */}
                    <TextField
                      label="IATA"
                      required
                      fullWidth
                      onChange={(e) => setIataCode(e.target.value)}
                      value={iataCode}
                      sx={{
                        marginTop: "8px",
                        borderRadius: "8px",
                      }}
                      variant="outlined"
                      placeholder="Entra nella IATA"
                    />
                  </div>

                  <div>
                    {/* <label className="text-[14px] font-medium text-[#1E1E1E]">Dst Code</label> */}
                    <TextField
                      label="Dst Code"
                      required
                      fullWidth
                      onChange={(e) => setDstCode(e.target.value)}
                      value={dstCode}
                      sx={{
                        marginTop: "8px",
                        borderRadius: "8px",
                      }}
                      variant="outlined"
                      placeholder="Inserisci il codice Dst"
                    />
                  </div>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <div>
                    {/* <label className="text-[14px] font-medium text-[#1E1E1E]">Dst Name</label> */}
                    <TextField
                      label="Dst Name"
                      required
                      fullWidth
                      onChange={(e) => setDstName(e.target.value)}
                      value={dstName}
                      sx={{
                        marginTop: "8px",
                        borderRadius: "8px",
                      }}
                      variant="outlined"
                      placeholder="Immettere il nome destinazione"
                    />
                  </div>

                  <div>
                    {/* <label className="text-[14px] font-medium text-[#1E1E1E]">Nation Code</label> */}
                    <TextField
                      label="Nation Code"
                      required
                      fullWidth
                      onChange={(e) => setNationCode(e.target.value)}
                      value={nationCode}
                      sx={{
                        marginTop: "8px",
                        borderRadius: "8px",
                      }}
                      variant="outlined"
                      placeholder="Inserisci il codice della Nazione"
                    />
                  </div>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <div>
                    {/* <label className="text-[14px] font-medium text-[#1E1E1E]">Nation Name</label> */}
                    <TextField
                      label="Nation Name"
                      required
                      fullWidth
                      onChange={(e) => setNationName(e.target.value)}
                      value={nationName}
                      sx={{
                        marginTop: "8px",
                        borderRadius: "8px",
                      }}
                      variant="outlined"
                      placeholder="Inserisci il nome della nazione"
                    />
                  </div>
                </Box>
              </Box>

              <Divider sx={{ color: "#E3E3E3", marginTop: "0px" }} />
              <div className="mt-[10px] flex items-center justify-between pl-[34px] pr-[28px]">
                <Button
                  variant="outlined"
                  sx={{ width: "153px", height: "43px", textTransform: "capitalize" }}
                  onClick={handleDialogClose}
                  color="error"
                >
                  Annulla
                </Button>
                <PrimaryButton
                  disabled={isLoading}
                  buttonText={"Crea"}
                  width={128}
                  height="43px"
                  onClick={() => {
                    if (hotelCode && hotelName) {
                      handleSalvaClick();
                    }
                  }}
                />
              </div>
            </Dialog>
          )
        }

      </Box>
    </Layout>
  );
}

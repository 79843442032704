import { Box, Divider, TextField, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import upload from "../../assets/upload.svg";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { convertToHTML } from "draft-convert";
import useLandingPage from "../../library/hooks/useLandingPage/useLandingPage";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar2.svg";
import { DatePicker } from "rsuite";
import Switch from "@mui/material/Switch";
import { format } from 'date-fns';
import TableApp from "Components/TableApp/TableApp";
import AddLocationModal from "Components/AddLocationModel/AddLocationModel";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

export default function LandingPageInfo({
  setSidebarIndex,
  propPageUrl,
  propPageTitle,
  propH1Page,
  propH2Page,
  propPageMarkdown,
  propBannerImage,
  propBannerImageEditCase,
  sendValueToParent,
  propStartDate,
  propEndDate,
  propIsSearchable,
  propLocation,
  propPageMarkdown2
}) {
  /**
   * Api Context
   */

  //helper state
  const { callLandingPageUrlAlreadyExistApi } = useLandingPage();

  const [selectedImageFileChanged, setSelectedImageFileChanged] =
    useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(
    propBannerImageEditCase == null
      ? propBannerImage?.length > 0
        ? propBannerImage[0]
        : null
      : propBannerImageEditCase
  );
  const [selectedImage, setSelectedImage] = useState(
    selectedImageFile != null ? URL.createObjectURL(selectedImageFile) : null
  );
  // const [editorState, setEditorState] = useState(
  //   propPageMarkdown
  //     ? EditorState.createWithContent(
  //       ContentState.createFromBlockArray(convertFromHTML(propPageMarkdown))
  //     )
  //     : ""
  // );
  console.log(propH1Page);
  const [editorState, setEditorState] = useState(propPageMarkdown);
  const [editorState2, setEditorState2] = useState(propPageMarkdown2);
  const [pageUrl, setPageUrl] = useState(propPageUrl);
  const [pageTitle, setPageTitle] = useState(propPageTitle);
  const [h1Page, setH1Page] = useState(propH1Page ?? "");
  const [h2Page, setH2Page] = useState(propH2Page ?? "");
  const [location, setLocation] = useState(propLocation);
  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  /**
   * for validation
   */
  const [pageUrlError, setPageUrlError] = useState(false);
  const [pageURLAlreadyExistError, setPageURLAlreadyExistError] =
    useState(false);
  const [pageTitleError, setPageTitleError] = useState(false);
  const [startDate, setStartDate] = useState(propStartDate);
  const [endDate, setEndDate] = useState(propEndDate);
  const [isSearchable, setIsSearchable] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Località",
      minWidth: 300,
    },
    {
      field: "name",
      headerName: "Name Localita",
      minWidth: 150,
    },
    {
      headerName: "AvesLocations",
      minWidth: 200,
      valueGetter: (params) =>
        params?.data?.avesLocations ? params?.data?.avesLocations?.length : 0,
    },
  ];

  useEffect(() => {
    if (propIsSearchable) {
      setIsSearchable(propIsSearchable)
    }
  }, [propIsSearchable])

  const handleLoactionOpenModal = () => {
    setSelectedLocation(null); // Reset selected location after edit
    setIsAddLocationModalOpen(true);
    setLocation(null)
  };

  const handleLoactionCloseModal = () => {
    setIsAddLocationModalOpen(false);
  };

  // Function to handle deletion of a location
  const handleDeleteLocation = (row) => {
    // const locationName = row.data.name; // Assuming the location name is stored in 'name'

    // // Show a confirmation alert
    const userConfirmed = window.confirm(
      `Vuoi rimuovere la località ${row.data.name}?`
    );

    // // Proceed with deletion if the user confirmed
    // if (userConfirmed) {
    //   setLocation(row.data);
    // }
    //const newData = location.map((item) => item?.name !== row.data.name);
    setLocation(null);
  };

  // Function to handle editing of a location (can be customized based on your requirements)
  const handleEditLocation = (row) => {
    setIsAddLocationModalOpen(true); // Open the modal
    setSelectedLocation(row.data); // Set the selected data for editing
    // You can implement the edit logic here (for example, open a modal for editing)
  };

  // Function to handle the modal submit click
  const submitLocationClick = (data) => {
    setLocation(data)
    setIsAddLocationModalOpen(false);
    setSelectedLocation(null); // Reset selected location after edit
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    // Check if files are selected
    if (files.length > 0) {
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setSelectedImageFile(file);
      setSelectedImageFileChanged(true);
    } else {
      // If no files are selected, remove the selected image
      setSelectedImage(null);
      setSelectedImageFile(null);
      setSelectedImageFileChanged(false);
    }
  };

  const handleDateChange = (type, value) => {
    const formattedDate = format(value, 'yyyy-MM-dd');
    if (type === 'start') {
      setStartDate(formattedDate); // Update start date
    } else if (type === 'end') {
      setEndDate(formattedDate); // Update end date
    }
  };

  const handlePageUrlBlur = async () => {
    if (pageUrl == "") return;

    await callLandingPageUrlAlreadyExistApi(pageUrl)
      .then((response) => {
        if (response && response?.isUrlExist == true) {
          setPageUrlError(true);
          setPageURLAlreadyExistError(true);
        } else {
          setPageURLAlreadyExistError(false);
        }
      })
      .catch((err) => {
        console.error("Error: callLandingPageUrlAlreadyExistApi!", err);
      });
  };

  const handleNext = () => {
    // Validate pageUrl and pageTitle
    if (pageUrl === "") {
      setPageUrlError(true);
    }
    if (pageTitle === "") {
      setPageTitleError(true);
    }

    if (pageUrl !== "" && pageTitle !== "" && !pageURLAlreadyExistError) {
      setSidebarIndex(2);

      let description_html = "";
      let pageMarkdown2_html = "";
      let contentState;
      if (editorState && editorState !== "") {
       
        // contentState = editorState?.getCurrentContent();
        // description_html = contentState ? convertToHTML({})(contentState) : "";
        description_html = editorState;
      }
      if (editorState2 && editorState2 !== "") {
       
        pageMarkdown2_html = editorState2;
      }
      //call back
      sendValueToParent(
        pageUrl,
        pageTitle,
        h1Page,
        h2Page,
        description_html,
        selectedImageFile,
        selectedImageFileChanged,
        startDate,
        endDate,
        isSearchable,
        location,
        pageMarkdown2_html
      );
    }
  };
  const IsSearchable = () => {
    setIsSearchable(!isSearchable);
  };

  return (
    <Box
      sx={{
        marginLeft: "34px",
        width: "1003px",
        minHeight: "815px",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Create the page URL <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            The system will automatically check that it is not already present
          </Typography>
        </Box>
        <Box sx={{ display: "block" }}>
          <TextField
            sx={{
              marginTop: "10px",
              width: "564px",
              height: "48px",
              borderRadius: "5px",
              borderColor: pageUrlError ? "red" : undefined,
            }}
            variant="outlined"
            placeholder="https://domain.com/"
            value={pageUrl}
            error={pageUrlError}
            onBlur={handlePageUrlBlur}
            onChange={(e) => {
              setPageUrl(e.target.value);
              setPageUrlError(e.target.value === "");
            }}
          />

          {pageURLAlreadyExistError && (
            <p style={{ marginTop: "15px", color: "red" }}>
              please change Url, It is already taken.
            </p>
          )}
        </Box>
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Date di Default <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            Queste date verranno utilizzate dai server per salvare tutte le
            richieste nella cache.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "34px",
            gap: "10px",
          }}
        >
          {/* <DatePicker
            style={{
              height: "53px",
              background: "#FFFFFF",
              borderColor: "#26529C",
              borderWidth: "1px",
              borderRadius: "4px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            // menuStyle={{ right: 0 }}
            placement="bottomEnd"
            caretAs={CalendarIcon}
            appearance="subtle"
            placeholder="Select Date 1"
            value={startDate}
            onChange={handleDateChange}
          /> */}
          <DatePicker
            style={{
              height: "53px",
              background: "#FFFFFF",
              borderColor: "#26529C",
              borderWidth: "1px",
              borderRadius: "4px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginBottom: "10px"
            }}
            placement="bottomEnd"
            caretAs={CalendarIcon}
            appearance="subtle"
            placeholder="Select Start Date"
            format="yyyy-MM-dd"
            value={startDate ? new Date(startDate) : null} // Convert to Date object for DatePicker
            onChange={(value) => handleDateChange('start', value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "34px",
            gap: "10px",
          }}
        >
          {/* <DatePicker
            style={{
              height: "53px",
              background: "#FFFFFF",
              borderColor: "#26529C",
              borderWidth: "1px",
              borderRadius: "4px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            // menuStyle={{ right: 0 }}
            placement="bottomEnd"
            caretAs={CalendarIcon}
            appearance="subtle"
            placeholder="Select Date 2"
            value={endDate}
            onChange={handleDateChange}
          /> */}
          <DatePicker
            style={{
              height: "53px",
              background: "#FFFFFF",
              borderColor: "#26529C",
              borderWidth: "1px",
              borderRadius: "4px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            placement="bottomEnd"
            caretAs={CalendarIcon}
            appearance="subtle"
            placeholder="Select End Date"
            format="yyyy-MM-dd"
            value={endDate ? new Date(endDate) : null} // Convert to Date object for DatePicker
            onChange={(value) => handleDateChange('end', value)}
          />
        </Box>
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          marginTop: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          minHeight: "237px",
          padding: "15px 20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: "24px", fontWeight: "500px" }}>
            Località
          </Typography>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "400",
              color: "#C7C7C7",
            }}
          >
            Questa funzionalità collega una località principale a delle
            secondarie, e nel sito web verrà mostrata la ricerca collegata,
            ricorda che non vale il viceversa.
          </Typography>
        </Box>
        <Box
          sx={{
            margin: "10px 0px 10px 30px",
            width: "876px",
            display: "flex",
            alignItems: "center",
            borderRadius: "10px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              minWidth: "100%",
              overflowX: "auto",
            }}
          >
            <TableApp
              rows={location ? [location] : []}
              columns={columns}
              pagination={true}
              onEdit={handleEditLocation}
              onDelete={handleDeleteLocation}
            />
          </Box>
          {
            !location && (
              <Button
                variant="outlined"
                fullWidth
                onClick={handleLoactionOpenModal}
                sx={{
                  marginTop: "5px",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                  Aggiungi Nuova
                </Typography>
              </Button>
            )
          }

          <AddLocationModal
            isOpen={isAddLocationModalOpen}
            onClose={handleLoactionCloseModal}
            submitClick={submitLocationClick}
            initialData={selectedLocation} // Pass the selected location data for editing
          />
        </Box>
      </Box>

      <Box
        sx={{
          // marginTop: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Page title <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            It will be inserted in the head of the page, i.e. shown in the tab
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
            borderColor: pageTitleError ? "red" : undefined,
          }}
          variant="outlined"
          placeholder="Page Title"
          value={pageTitle}
          error={pageTitleError}
          onChange={(e) => {
            setPageTitle(e.target.value);
            setPageTitleError(e.target.value === "");
          }}
        />
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          // marginTop: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              h1 of the page
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the h1 text below the search bar on the landing page
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
          }}
          variant="outlined"
          placeholder="h1 of the page"
          value={h1Page}
          onChange={(e) => setH1Page(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              h2 of the page
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the h2 text below the h1 on the landing page
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
          }}
          variant="outlined"
          placeholder="h2 of the page"
          value={h2Page}
          onChange={(e) => setH2Page(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          backgroundColor: "white",
          borderRadius: "10px",
          minHeight: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Page markdown
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the text under the h2 on the landing page and is HTML
            markdown
          </Typography>
        </Box>
        <Box
          style={{
            margin: "0px 10px 10px 40px",
            width: "600px",
            //border: "1px solid #e3e3e3",
            borderRadius: "5px",
            padding: "10px",
            minHeight: "250px",
          }}
        >
          {/* <Editor
            placeholder="Markdown Text"
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={setEditorState}
          /> */}

          <div data-color-mode="light">
            <MDEditor
              value={editorState}
              onChange={setEditorState}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
          </div>
        </Box>
      </Box>

      <Divider sx={{ margin: "20px 0px" }} />

      <Box
        sx={{
          marginTop: "15px",
          backgroundColor: "white",
          borderRadius: "10px",
          minHeight: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Page markdown 2
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the text under the h2 on the landing page and is HTML
            markdown 2
          </Typography>
        </Box>
        <Box
          style={{
            margin: "0px 10px 10px 40px",
            width: "600px",
            //border: "1px solid #e3e3e3",
            borderRadius: "5px",
            padding: "10px",
            minHeight: "250px",
          }}
        >
          {/* <Editor
            placeholder="Markdown Text"
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={setEditorState}
          /> */}

          <div data-color-mode="light">
            <MDEditor
              value={editorState2}
              onChange={setEditorState2}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
          </div>
        </Box>
      </Box>

      <Divider sx={{ margin: "20px 0px" }} />      

      <Box
        sx={{
          marginTop: "15px",
          backgroundColor: "white",
          borderRadius: "10px",
          // height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Banner Landing Page
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                paddingTop: "6px",
                marginLeft: "10px",
              }}
            >
              (optional)
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the text under the h2 on the landing page and is HTML
            markdown
          </Typography>
        </Box>
        {selectedImage ? (
          <div>
            <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
              <img
                src={selectedImage}
                alt="Selected"
                style={{
                  width: "564px",
                  height: "132px",
                  cursor: "pointer",
                }}
              />
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </label>
          </div>
        ) : (
          <div>
            <button
              style={{
                width: "564px",
                height: "132px",
                border: "1px dashed #26529C",
                borderRadius: "10px",
                fontSize: "32px",
                fontWeight: "600",
                cursor: "pointer",
                color: "#26529C",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => {
                const inputElement = document.getElementById("image-upload");
                inputElement.click();
              }}
            >
              <img
                src={upload}
                alt="upload button"
                style={{ marginTop: "15px" }}
              />
              <label
                htmlFor="image-upload"
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                <Typography sx={{ fontSize: "24px", fontWeight: "400" }}>
                  Upload the file to .webp
                </Typography>
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </button>
          </div>
        )}
      </Box>

      <Divider sx={{ marginTop: "16px" }} />

      <Box sx={{ display: "flex", alignItems: "center", padding: "20px", justifyContent: 'end', marginRight: '15px' }}>
        <p style={{ margin: 0, fontWeight: "bold" }}>Searchable</p>
        <Switch checked={isSearchable} onChange={IsSearchable} color="primary" />
        <p style={{ margin: 0 }}>{isSearchable ? "on" : "off"}</p>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", padding: "20px" }}>
        <Button
          sx={{
            width: "186px",
            height: "44px",
            backgroundColor: "#26529C",
            color: "white",
            "&:hover": { backgroundColor: "#26529C" },
          }}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
